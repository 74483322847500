<template>
  <div class="vjs-tree" style="font-size: 0.8em">
    <div class="vjs-tree__node">
      <span>
        <span class="vjs-tree__brackets">
          {
        </span>
      </span>
    </div>
    <div class="vjs-tree__node">
      <div class="vjs-tree__indent has-line"></div>
      <span class="vjs-key">"result": </span>
      <span class="vjs-value vjs-value__string"> "{{ displayResult }}" </span>
    </div>
    <div class="vjs-tree__node">
      <span>
        <span class="vjs-tree__brackets">
          }
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "JavascriptEvaluateResultTestOutput",
  props: {
    displayResult: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
